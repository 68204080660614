const getTimeDifference = (dataTime) => {
  switch (dataTime) {
    case "1m":
      return 10 * 60 * 1000; // 10 minute in milliseconds
    case "3m":
      return 30 * 60 * 1000; // 30 minutes in milliseconds
    case "5m":
      return 50 * 60 * 1000; // 50 minutes in milliseconds
    case "15m":
      return 150 * 60 * 1000; // 150 minutes in milliseconds
    case "30m":
      return 300 * 60 * 1000; // 300 minutes in milliseconds
    case "1h":
      return 600 * 60 * 1000; // 10 hours in milliseconds
    case "2h":
      return 2 * 600 * 60 * 1000; // 20 hours in milliseconds
    case "4h":
      return 4 * 600 * 60 * 1000; // 40 hours in milliseconds
    case "8h":
      return 8 * 600 * 60 * 1000; // 80 hours in milliseconds
    case "1d":
      return 24 * 600 * 60 * 1000; // 10 days in milliseconds
    case "1w":
      return 7 * 24 * 420 * 60 * 1000; // 7 weeks in milliseconds
    case "1M":
      return 30 * 24 * 600 * 60 * 1000; // 10 months (approximated to 300 days) in milliseconds
    default:
      return 10 * 60 * 1000; // default to 5 minutes if unknown
  }
};

export {
    getTimeDifference
}