import { useTranslation } from "react-i18next";
import { ReactComponent as ArrowDownIcon } from "../../assets/icons/ArrowDownIcon.svg";
import { useState, useRef, useEffect } from "react";

import { useSelector, useDispatch } from "react-redux";
import { getUserWallet } from "../../toolkitReducers/actions.slice";
import { useDepositSumMutation } from "../../toolkitReducers/cryptouch";
import { NetworkSelectBlock } from "./NetworkSelectBlock";

export const Deposit = ({ switchDone }) => {
  const { currencies, wallet } = useSelector((state) => state.state);
  const [expanded, setExpanded] = useState(false);
  const [isSmallerSum, showSmallerSum] = useState(false);
  const [isSum, setIsSum] = useState("");
  const [activeMenu, setActiveMenu] = useState(0);
  const [isCopied, setCopied] = useState(false);
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const inputRef = useRef(null);
  const [depositSum] = useDepositSumMutation();

  useEffect(() => {
    dispatch(getUserWallet(currencies[activeMenu].value));
  }, [activeMenu, currencies, dispatch]);
  const chooseMenuItem = (index, name) => {
    setActiveMenu(index);
    setExpanded(false);
    showSmallerSum(false);

    dispatch(getUserWallet(currencies[index].value));
  };
  const sumChange = (e) => {
    if (Number(e.target.value) < 0) {
      showSmallerSum(true);
    } else {
      showSmallerSum(false);
      setIsSum(e.target.value);
    }
  };
  const copyText = () => {
    navigator.clipboard.writeText(inputRef.current.value);

    setCopied(true);
    setTimeout(() => setCopied(false), 500);
  };

  const playNext = () => {
    if (Number(isSum) === 0) {
      return showSmallerSum(true);
    }

    if ("done") {
      switchDone(true);

      const obj = {
        sum: isSum,
        typeSum: currencies[activeMenu].value,
        address: inputRef.current.value,
      };
      depositSum(obj);
    }
  };

  return (
    <div className="deposit-content-wrapper">
      <h2>{t("Пополнить Main Balance")}</h2>
      <p className="deposit-window-label">{t("Выбор монеты")}</p>
      <div
        className={
          expanded
            ? "deposit-dropdown-container expanded"
            : "deposit-dropdown-container"
        }
      >
        <button
          tabIndex="0"
          aria-expanded="false"
          aria-controls="dropdown-2"
          onClick={() => setExpanded(!expanded)}
          className="deposit-dropdown-container-btn"
        >
          <span>
            <ArrowDownIcon />
          </span>
          <span data-button-label>{currencies[activeMenu].name}</span>
        </button>
        {expanded && (
          <ul className="deposit-dropdown-list" data-dropdown id="dropdown-2">
            {currencies.map(({ index, name }) => {
              if (index === 0) {
                return (
                  <li key={index}>
                    <button
                      data-dropdown-button
                      role="tab"
                      type="button"
                      tabIndex="0"
                      aria-expanded={true}
                      id={`tab-replenish-deposit-${index}`}
                      aria-controls={`tabpanel-replenish-deposit-${index}`}
                      onClick={() => chooseMenuItem(index, name)}
                      aria-selected="true"
                    >
                      <span>
                        <ArrowDownIcon />
                      </span>
                      <span>{name}</span>
                    </button>
                  </li>
                );
              }
              return (
                <li key={index}>
                  <button
                    data-dropdown-button
                    role="tab"
                    type="button"
                    tabIndex="0"
                    aria-expanded={true}
                    id={`tab-replenish-deposit-${index}`}
                    aria-controls={`tabpanel-replenish-deposit-${index}`}
                    onClick={() => chooseMenuItem(index, name)}
                    aria-selected="true"
                  >
                    <span>{name}</span>
                  </button>
                </li>
              );
            })}
          </ul>
        )}
      </div>
      <div className="deposit-window__currency-address-wrapper">
        <input
          className="copy-email"
          required
          onChange={sumChange}
          value={isSum}
          type="number"
          placeholder={t("Сумма")}
          name="sum"
        />
        {isSmallerSum && (
          <div className="modal-dialog__invoice-description-wrapper">
            <p className="modal-dialog__invoice-description">
              {t("Внимание!")}
              <br /> {t("сумма должна быть больше")} 0
            </p>
          </div>
        )}
      </div>
      <NetworkSelectBlock />
      <div>
        <p className="deposit-window-label">
          {currencies[activeMenu].name + " " + t("адрес")}
        </p>
        <div className="deposit-window__currency-address-wrapper">
          <label
            className="copy-email-label"
            aria-roledescription="button"
            htmlFor="payment-address-eth"
            onClick={() => copyText()}
          >
            <svg
              width="16"
              height="21"
              viewBox="0 0 16 21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <linearGradient
                id="paint0_linear_2004_41"
                x1="5.2625"
                y1="20.1423"
                x2="5.26247"
                y2="9.1644e-07"
                gradientUnits="userSpaceOnUse"
              >
                <stop stopColor="#337FD0" />
                <stop offset="1" stopColor="#9F38D2" />
              </linearGradient>
              <path
                fill="url(#paint0_linear_2004_41)"
                d="M10.0603 20.3636H2.96984C1.33222 20.3636 0 18.9363 0 17.1818V6.40341C0 4.6489 1.33222 3.22159 2.96984 3.22159H10.0603C11.6979 3.22159 13.0302 4.6489 13.0302 6.40341V17.1818C13.0302 18.9363 11.6979 20.3636 10.0603 20.3636ZM2.96984 4.8125C2.1511 4.8125 1.48492 5.52623 1.48492 6.40341V17.1818C1.48492 18.059 2.1511 18.7727 2.96984 18.7727H10.0603C10.8791 18.7727 11.5452 18.059 11.5452 17.1818V6.40341C11.5452 5.52623 10.8791 4.8125 10.0603 4.8125H2.96984ZM16 15.1932V3.18182C16 1.42731 14.6678 0 13.0302 0H4.78886C4.37877 0 4.0464 0.35609 4.0464 0.795455C4.0464 1.23482 4.37877 1.59091 4.78886 1.59091H13.0302C13.8489 1.59091 14.5151 2.30464 14.5151 3.18182V15.1932C14.5151 15.6325 14.8474 15.9886 15.2575 15.9886C15.6676 15.9886 16 15.6325 16 15.1932Z"
              />
            </svg>
          </label>
          <input
            className="copy-email"
            type="text"
            name="payment-address-eth"
            id="payment-address-eth"
            readOnly
            ref={inputRef}
            defaultValue={wallet === "" ? wallet : wallet.wallet_address}
          />
          <div
            className={`form-container__successful-message-wrapper`}
            aria-hidden={isCopied ? "false" : "true"}
          >
            <span className="text-copied">Copied to clipboard</span>
          </div>
        </div>
      </div>

      <div className="deposit-window__info-wrapper">
        <p>
          {t(
            "Внимание, проверяйте адрес и тип монеты перед отправкой средств!"
          )}
        </p>
        <p>
          {t(
            "Отправка другого типа средств, или суммой менее 10 USDT, может привести к их утере."
          )}
        </p>
      </div>
      <button className="btn next" onClick={playNext}>
        {t("Далее")}
      </button>
    </div>
  );
};
