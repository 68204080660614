import { useState } from "react";
import { useSelector } from "react-redux";
import { selectCurrencies } from "../../toolkitReducers/selectors";
import { ReactComponent as Arrow } from "../../assets/icons/modals/arrow-expand.svg";


export const CoinSelect = ({ handleCoinChange }) => {
  const currencies = useSelector(selectCurrencies);
  const [isOpenMenu, setOpenMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);

  const chooseMenuItem = (index) => {
    setActiveMenu(index);
    setOpenMenu(false);
    handleCoinChange(index);
  };
  return (
    <div className={`coin-select ${isOpenMenu ? "expanded" : ""}`}>
      <button
        onClick={() => setOpenMenu(!isOpenMenu)}
        className="coin-select-expand-btn"
      >
        <span>{currencies[activeMenu].name}</span>
        <Arrow />
      </button>

      <ul className={`coin-select-menu ${isOpenMenu ? "expanded" : ""}`}>
        {currencies.map((item) => {
          return (
            <li key={item.index}>
              <button type="button" onClick={() => chooseMenuItem(item.index)}>
                {item.name}
              </button>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
