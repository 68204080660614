import { useState, useEffect } from "react";


export const useCountries = () => {
    const [countries, setCountries] = useState([]);
    useEffect(() => {
        fetch(
          "https://countriesnow.space/api/v0.1/countries/iso"
        )
          .then((response) => response.json())
          .then((data) => {
            console.log(data)
            setCountries(data.data);
  
          });
      }, []);

      return {
        countries
      }
}