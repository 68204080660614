import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { privateFetch} from "../helpers";

import { fetchRefferals } from "./operations";

const handleRejected = (state, action) => {
  state.fetching = "rejected";
  state.error = action.payload;
};
const handlePending = (state, action) => {
  state.fetching = true;
};

export const getCurrencies = createAsyncThunk(
  "async/getCurrencies",
  async function (param, options) {
    const response = await privateFetch("get_crypto_prices/");

    const data = await response.json();
    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
export const getCryptoPrices = createAsyncThunk(
  "async/getCryptoPrices",
  async function (param, { rejectWithValue }) {

    try {
      const response = await fetch(
        `${
          process.env.REACT_APP_API_URL
        }get_crypto_prices/?timestamp=${new Date().getTime()}`,
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json, text/html",
          },
        }
      );
      if (!response.ok) {
        const errorData = await response.json();
        return rejectWithValue(errorData);
      }

      const contentType = response.headers.get("Content-Type");
      let data;
      if (contentType.includes("application/json")) {
        data = await response.json();
      } else if (contentType.includes("text/html")) {
        data = await response.text();
      } else {
        throw new Error(`Unsupported content type: ${contentType}`);
      }

      return data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const getGlobalProfit = createAsyncThunk(
  "async/getGlobalProfit",
  async function (param, options) {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "get_global_profit/"
    );

    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const getUserSessions = createAsyncThunk(
  "async/getUserSessions",
  async function (param, options) {
    const response = await privateFetch("get_user_sessions/");

    const data = await response.json();
    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
export const getUserWallet = createAsyncThunk(
  "async/getUserWallet",
  async function (param, options) {
    const response = await privateFetch(
      "get_wallet/?type=" + param.toUpperCase()
    );
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const getRisks = createAsyncThunk(
  "async/getRisks",
  async function (param, options) {
    const response = await privateFetch("get_user_risks/");
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const getGlobalStatistics = createAsyncThunk(
  "async/getGlobalStatistics",
  async function (param, options) {
    const response = await privateFetch("get_global_statistics/");
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
export const getUserRisks = createAsyncThunk(
  "async/getUserRisks",
  async function (param, options) {
    const response = await privateFetch("get_user_risks/");
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const setUserRisks = createAsyncThunk(
  "async/setUserRisks",
  async function (param, options) {
    const response = await privateFetch("set_risk/");
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const getTransactionsHistory = createAsyncThunk(
  "async/getTransactionsHistory",
  async function (param, options) {
    const response = await privateFetch("transaction_history/");
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
export const setBetHistory = createAsyncThunk(
  "async/setBetHistory",
  async function (param, options) {
    const response = await privateFetch("bet_history/");
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return { data, oldState: options.getState() };
  }
);

export const setSum = createAsyncThunk(
  "async/setSum",
  async function (param, options) {
    const response = await privateFetch("set_sum/", {
      method: "POST",
      // body: JSON.stringify({
      //   sum: param.sum,
      //   type: param.typeSum,
      // }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
export const depositSum = createAsyncThunk(
  "async/depositSum",
  async function (param, options) {
    const response = await privateFetch("deposit_sum/", {
      method: "POST",
      body: JSON.stringify({
        sum: param.sum,
        type: param.typeSum,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
export const setRisks = createAsyncThunk(
  "async/setRisks",
  async function (param, options) {
    const response = await privateFetch("set_risk/", {
      method: "POST",
      body: JSON.stringify({
        current_risk: param.current_risk,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const setWithdrawal = createAsyncThunk(
  "async/setWithdrawal",
  async function (param, options) {
    const response = await privateFetch("withdrawal_sum/", {
      method: "POST",
      body: JSON.stringify({
        withdrawal_sum: param.withdrawal_sum,
        currency: param.currency,
        address: param.address,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const setBinary = createAsyncThunk(
  "async/setBinary",
  async function (param, options) {
    const response = await privateFetch("make_bet/", {
      method: "POST",
      body: JSON.stringify({
        bet_type: param.upDownValue === "up" ? "Higher" : "Lower",
        amount: param.investment,
        currency: param.selectedPair,
        expiration_time: param.formTime,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const setTransfer = createAsyncThunk(
  "async/setTransfer",
  async function (param, options) {
    const response = await privateFetch("balance_transaction/", {
      method: "POST",
      body: JSON.stringify({
        amount: param.withdrawal_sum,
        from_ticker: param.from_ticker,
        to_ticker: param.to_ticker,
        from_balance: param.from,
        to_balance: param.to,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const setKiss = createAsyncThunk(
  "async/setKiss",
  async function (param, options) {
    const formData = new FormData();

    // Добавляем текстовые данные
    formData.append("birth_date", param.birth_date);
    formData.append("city", param.city);
    formData.append("country", param.country);
    formData.append("name", param.name);
    formData.append("second_name", param.second_name);
    formData.append("telegram", param.telegram);
    formData.append("phone", param.phone);

    // Добавляем изображение в поле document_image
    formData.append("document_image", param.document_image.selectedFile);

    const response = await privateFetch(
      "submit_user_data/",
      {
        method: "POST",
        body: formData,
      },
      true
    );
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
const fetchUserIp = async () => {
  const res = await fetch(
    `https://api.ipdata.co?api-key=${process.env.REACT_APP_JSON_API_KEY}`
  );
  return res.json();
};
export const getUserIp = createAsyncThunk(
  "async/getUserIp",
  async (_, thunkAPI) => {
    try {
      const data = await fetchUserIp();
      return data.ip;
    } catch (error) {
      thunkAPI.rejectWithValue(error.message);
    }
  }
);

export const getRefferals = createAsyncThunk(
  "async/getRefferals",
  async (_, thunkAPI) => {
    try {
      const res = await fetchRefferals();
      return res;
    } catch (error) {
      thunkAPI.rejectWithValue(error.message);
    }
  }
);

const actionsSlice = createSlice({
  name: "state",
  initialState: {
    currencies: [
      { value: "usdt", name: "USDT", index: 0 },
      { value: "btc", name: "Bitcoin", index: 1 },
      { value: "eth", name: "Ethereum", index: 2 },
    ],
    // coinPriceBTC: null,
    // coinPriceETH: null,
    cryptoPrices: null,
    ipAdresses: [],
    sessions: null,
    wallet: "",
    globalProfit: null,
    currenciesFetch: null,
    risks: null,
    betHistory: null,
    pendingBets: [],
    transactionsHistory: null,
    statistics: null,
    tooltip: false,
    tooltipText: "",
    fetching: false,
    registered: false,
    kissFields: null,
    referrals: null,
    error: "",
  },
  reducers: {
    transportKissFields: (state, action) => {
      state.kissFields = action.payload;
    },

    clearKissFields: (state) => {
      state.kissFields = null;
    },
    openTooltip: (state, action) => {
      state.tooltip = true;
    },
    closeTooltip: (state) => {
      state.tooltip = false;
    },
    textTooltip: (state, action) => {
      state.tooltipText = action.payload;
    },
    textTooltipClear: (state, action) => {
      state.tooltipText = "";
    },
  },
  extraReducers: (builder) => {
    //get getGlobalProfit
    builder.addCase(getGlobalProfit.pending, handlePending);
    builder.addCase(getGlobalProfit.fulfilled, (state, action) => {
      state.fetching = false;

      state.globalProfit = action.payload;
      state.error = "";
    });
    builder.addCase(getGlobalProfit.rejected, handleRejected);

    //get currencies
    builder.addCase(getCurrencies.pending, handlePending);
    builder.addCase(getCurrencies.fulfilled, (state, action) => {
      state.fetching = false;

      const { payload } = action;

      const joinCurrencies = {
        usdt: {
          name: "USDT",
          rate: payload.USDT,
        },
        btc: {
          name: "BTC",
          rate: payload.BTC,
        },
        eth: {
          name: "ETH",
          rate: payload.ETH,
        },
      };

      state.currenciesFetch = joinCurrencies;

      state.error = "";
    });
    builder.addCase(getCurrencies.rejected, handleRejected);
    //get user risks
    builder.addCase(getUserRisks.pending, handlePending);
    builder.addCase(getUserRisks.fulfilled, (state, action) => {
      state.fetching = false;

      // state.statistics = action.payload
      state.risks = action.payload;
      state.error = "";
    });
    builder.addCase(getUserRisks.rejected, handleRejected);
    //set risks
    builder.addCase(setRisks.pending, handlePending);
    builder.addCase(setRisks.fulfilled, (state, action) => {
      state.fetching = false;
      state.tooltip = true;

      // state.statistics = action.payload
      state.error = "";
    });
    builder.addCase(setRisks.rejected, handleRejected);
     //setBetHistory
     builder.addCase(setBetHistory.pending, handlePending);
     builder.addCase(setBetHistory.fulfilled, (state, action) => {
       state.isLoading = false;
 
       const { bet_history } = action.payload.data;
       state.betHistory = bet_history;
 
       let pendings = bet_history.filter((bet) => bet.status === "Pending");
 
       state.pendingBets = pendings;
 
       state.error = null;
     });
     builder.addCase(setBetHistory.rejected, handleRejected);
    //getTransactionsHistory
    builder.addCase(getTransactionsHistory.pending, handlePending);
    builder.addCase(getTransactionsHistory.fulfilled, (state, action) => {
      state.fetching = false;

      state.transactionsHistory = action.payload;
      state.error = "";
    });
    builder.addCase(getTransactionsHistory.rejected, handleRejected);

    //set Kiss
    builder.addCase(setKiss.pending, handlePending);
    builder.addCase(setKiss.fulfilled, (state, action) => {
      state.fetching = false;
      // state.tooltip = true

      // state.statistics = action.payload
      state.error = "";
    });
    builder.addCase(setKiss.rejected, handleRejected);

    //set Withdrawal
    builder.addCase(setWithdrawal.pending, handlePending);
    builder.addCase(setWithdrawal.fulfilled, (state, action) => {
      state.fetching = false;
      state.tooltip = true;

      // state.statistics = action.payload
      state.error = "";
    });
    builder.addCase(setWithdrawal.rejected, handleRejected);

    //set make bet setBinary
    builder.addCase(setBinary.pending, handlePending);
    builder.addCase(setBinary.fulfilled, (state, action) => {
      state.fetching = false;
      state.tooltipRedirect = false;
      state.tooltipText =
        "Ставка принята. Прогресс можете отслеживать во вкладке Баланс - История Сеансов";

      // state.statistics = action.payload
      state.error = "";
    });
    builder.addCase(setBinary.rejected, handleRejected);

    //set Transfer
    builder.addCase(setTransfer.pending, handlePending);

    builder.addCase(setTransfer.fulfilled, (state, action) => {
      state.fetching = false;

      if (action.payload?.error) {
        state.error = action.payload?.error;
      } else {
        // state.tooltip = true
        // state.tooltipRedirect = false
        // state.tooltipText = "Заявка на перевод средств принята"

        state.error = "";
      }
    });

    builder.addCase(setTransfer.rejected, handleRejected);

    //set Sum
    builder.addCase(setSum.pending, handlePending);
    builder.addCase(setSum.fulfilled, (state, action) => {
      state.fetching = false;

      // state.statistics = action.payload
      state.error = "";
    });
    builder.addCase(setSum.rejected, handleRejected);
    //deposit Sum
    builder.addCase(depositSum.pending, handlePending);
    builder.addCase(depositSum.fulfilled, (state, action) => {
      state.fetching = false;

      // state.statistics = action.payload
      state.error = "";
    });
    builder.addCase(depositSum.rejected, handleRejected);

    //set User risks
    builder.addCase(setUserRisks.pending, handlePending);
    builder.addCase(setUserRisks.fulfilled, (state, action) => {
      state.fetching = false;

      // state.statistics = action.payload
      state.tooltip = true;
      state.error = "";
    });
    builder.addCase(setUserRisks.rejected, handleRejected);

    //globalStatistics
    builder.addCase(getGlobalStatistics.pending, handlePending);
    builder.addCase(getGlobalStatistics.fulfilled, (state, action) => {
      state.fetching = false;

      state.statistics = action.payload;
      state.error = "";
    });
    builder.addCase(getGlobalStatistics.rejected, handleRejected);

    //risks
    builder.addCase(getRisks.pending, handlePending);
    builder.addCase(getRisks.fulfilled, (state, action) => {
      state.fetching = false;

      state.risks = action.payload;
      state.error = "";
    });
    builder.addCase(getRisks.rejected, handleRejected);

    //wallet
    builder.addCase(getUserWallet.pending, handlePending);
    builder.addCase(getUserWallet.fulfilled, (state, action) => {
      state.fetching = false;

      if (action.payload) state.wallet = action.payload;
      state.error = "";
    });
    builder.addCase(getUserWallet.rejected, handleRejected);

    //sessions
    builder.addCase(getUserSessions.pending, handlePending);
    builder.addCase(getUserSessions.fulfilled, (state, action) => {
      state.fetching = false;

      state.sessions = action.payload;
      state.error = "";
    });
    builder.addCase(getUserSessions.rejected, handleRejected);
    //   get ip adress for profile page sessions table
    builder.addCase(getUserIp.pending, handlePending);
    builder.addCase(getUserIp.fulfilled, (state, action) => {
      state.fetching = false;
      state.ipAdresses.push(action.payload);
      state.error = "";
    });
    builder.addCase(getUserIp.rejected, handleRejected);
    builder.addCase(getRefferals.pending, handlePending);
    builder.addCase(getRefferals.fulfilled, (state, action) => {
      state.fetching = false;
      state.referrals = action.payload;
      state.error = "";
    });
    builder.addCase(getRefferals.rejected, handleRejected);
    builder.addCase(getCryptoPrices.rejected, handleRejected);
    builder.addCase(getCryptoPrices.pending, handlePending);
    builder.addCase(getCryptoPrices.fulfilled, (state, action) => {
      state.fetching = false;
      state.cryptoPrices = action.payload;
      state.error = "";
    });
  },
});


export const {
  transportKissFields,
  clearKissFields,
  openTooltip,
  closeTooltip,
  textTooltip,
  textTooltipClear,
} = actionsSlice.actions;

export default actionsSlice.reducer;

// INITIAL STATE

// EXTRA REDUCERS
