import { useEffect } from "react";
import ReactDOM from "react-dom";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
export const ModalWindow = ({
  onModalClose,
  expanded,
  children,
  type = "common",
}) => {
  const theme = useSelector(selectTheme);
  useEffect(() => {
    if (expanded) {
      window.addEventListener("keydown", onModalClose);
      document.body.style.overflow = "hidden";
    }

    return () => {
      window.removeEventListener("keydown", onModalClose);
      document.body.style.overflow = "auto";
    };
  }, [onModalClose, expanded]);

  return ReactDOM.createPortal(
    <div
      className={expanded ? "overlay is-visible" : "overlay"}
      id="overlay"
      onClick={onModalClose}
    >
      <div
        className={`
    ${type === "extended" ? "modal-window-extended" : "modal-window"} 
    ${theme === "dark" ? "dark-profile" : ""}
  `}
      >
        {children}
      </div>
    </div>,
    document.getElementById("modal-root")
  );
};
