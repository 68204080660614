import { useState } from "react";
import { useSelector } from "react-redux";

function Dropdown({ handleCoinChange, options, value, onChange }) {
  const { currencies, wallet } = useSelector((state) => state.state);

  const [isOpenMenu, setOpenMenu] = useState(false);
  const [activeMenu, setActiveMenu] = useState(0);



  const chooseMenuItem = (index) => {
    setActiveMenu(index);
    setOpenMenu(false);
    handleCoinChange(index);
    // dispatch(getUserWallet(currencies[index].value))
  };

  return (
    <div className="custom-select">
      <button
        className="custom-select__btn"
        tabIndex="0"
        aria-expanded="true"
        onClick={() => setOpenMenu(true)}
        aria-controls="dropdown-2"
      >
        <svg
          className="icon"
          width="14"
          height="9"
          display={isOpenMenu ? "none" : "block"}
          viewBox="0 0 14 9"
          fill="none"
        >
          <path
            d="M0.721313 1.42859L6.86885 7.50002L13.0164 1.42859"
            stroke="#FFF831"
            color="currentColor"
            strokeWidth="1.5"
          />
        </svg>
        <span data-button-label className="custom-select__btn-text">
          {currencies[activeMenu].name}
        </span>
      </button>

      <ul
        data-dropdown
        className={`custom-select__dropdown ${isOpenMenu ? "active" : ""}`}
        list-menu="true"
        id="dropdown-2"
      >
        {currencies.map((item) => {
          return (
            <li
              className="custom-select__dropdown-option"
              // data-option-value={item.value}
              // aria-selected="false"
              key={item.index}
            >
              <button
                className="tabs__header-tab btn--reset link custom-select__dropdown-button"
                onClick={() => chooseMenuItem(item.index)}
              >
                {item.name}
              </button>
            </li>
          );
        })}
      </ul>

      <input
        type="hidden"
        className="custom-select__input-hidden"
        value="Ethereum"
      />
    </div>
  );
}

export { Dropdown };
