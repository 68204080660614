import Main from "./Pages/Main";
import { MainPage } from "./Pages/MainPage/MainPage";

// PUBLIC
import LoginPage from "./Pages/Auth/LoginPage";
import RestorePass from "./Pages/Auth/RestorePass";
import SetNewPass from "./Pages/Auth/SetNewPass";
import RegisterPage from "./Pages/Auth/RegisterPage";
import TwoFactorAuthPage from "./Pages/Auth/TwoFactorAuthPage";
import { TemporaryPage } from "./Pages/Auth/TemoraryPage";

// PRIVATE
import KYCPage from "./Pages/KYC/KYCPage";
import KYCStep1 from "./Pages/KYC/KYCStep1";
import KYCStep2 from "./Pages/KYC/KYCStep2";


import SettingsPage from "./Pages/SettingsPage/SettingsPage";
import ProfilePage from "./Pages/Profile/ProfilePage";
import { ReferalsPage } from "./Pages/Referals/ReferalsPage";
import BalancePage from "./Pages/Binary/BalancePage";

import StatisticPage from "./Pages/Statistics/StatisticPage";
import AlgoritmsPage from "./Pages/Algoritms/AlgoritmsPage";
import BinaryOptionPage from "./Pages/BinaryOption/BinaryOptionPage";
import NotFound from "./Pages/NotFound";

export const routes = [
  {
    path: "/",
      element: <Main />,
    children: [

      {
        path: "profile",
        element: <ProfilePage />,
      },
      {
        path: "balance",
        element: <BalancePage />,
      },
      {
        path: "statistics",
        element: <StatisticPage />,
      },
      {
        path: "profile/kyc",
        element: <KYCPage />,
        children: [
          {
            path: "step1",
            element: <KYCStep1 />,
          },
          {
            path: "step2",
            element: <KYCStep2 />,
          },
        ],
      },
      {
        path: "faq",
        element: <SettingsPage />,
      },
      {
        path: "settings",
        element: <SettingsPage />,
      },

      {
        path: "referals",
        element: <ReferalsPage />,
      },
      {
        path: "algoritms",
        element: <AlgoritmsPage />,
      },
      {
        path: "/ai_trading",
        element: <BinaryOptionPage />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
  {
    path: "/login",
    element: <LoginPage />,
  },
  {
    path: "/main",
    // element: <TemporaryPage />,
    element: <MainPage />,
  },
  {
    path: "/restore",
    element: <RestorePass />,
  },
  {
    path: "/confirm",
    element: <TwoFactorAuthPage />,
  },
  {
    path: "/new-password",
    element: <SetNewPass />,
  },
  {
    path: "/register",
    element: <RegisterPage />,
  },

];
