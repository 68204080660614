// this function provides refferals tree walkthrough to find nested refferals matching search query

function depthSearch(referrals, query) {
  if (!referrals?.length) return [];
  const results = [];

  const search = (referralList, query) => {
    referralList.forEach((referral) => {
      if (referral.email.toLowerCase().includes(query.toLowerCase())) {
        results.push(referral);
      }
      if (referral.referrals && referral.referrals.length > 0) {
        search(referral.referrals, query);
      }
    });
  };

  search(referrals, query);
  return results;
}

export { depthSearch };
