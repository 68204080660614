// use this function in transfer to display balances to depending from sender balance

function getAvailableToBalances(selectedFrom) {

    let availableBalancesTo;

    switch (selectedFrom) {
      case "bonus":
        availableBalancesTo = ["main"];
        break;
      case "profit":
        availableBalancesTo = ["main"];
        break;
      case "main":
        availableBalancesTo = ["trading"];
        break;
      default:
        availableBalancesTo = null;
    }

    return availableBalancesTo;

}

export {
    getAvailableToBalances
}