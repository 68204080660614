import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export const InvestmentControls = ({
  investment,
  profitFormula,
  setInvestment,
  setErrors,
  handleError,
  removeError,
  freeBalance,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    profitFormula();
  }, [investment, profitFormula]);
  const handleInputChange = (e) => {
    if (e.target.value > Number(freeBalance)) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "сумма не должна быть более баланса")
      );
    } else {
      removeError("сумма не должна быть более баланса");
    }
    if (Number(e.target.value) === 0) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "сумма не должна равняться 0")
      );
    } else {
      removeError("сумма не должна равняться 0");
    }
    setInvestment(parseInt(e.target.value));
    profitFormula();
  };

  const increaseInvestment = () => {
    removeError("сумма не должна равняться 0");
    if (investment > freeBalance) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "сумма не должна быть более баланса")
      );
      return;
    }
    setInvestment((prevInvestment) => {
      const newValue = parseInt((prevInvestment + 1).toFixed(2));
      return newValue >= 0 ? newValue : prevInvestment;
    });
    profitFormula();
  };

  const decreaseInvestment = () => {
    if (investment === 0) {
      setErrors((prevErrors) =>
        handleError(prevErrors, "сумма не должна равняться 0")
      );
      return;
    }
    setInvestment((prevInvestment) => {
      const newValue = parseInt((prevInvestment - 1).toFixed(2));
      return newValue >= 0 ? newValue : prevInvestment;
    });
    profitFormula();
  };

  return (
    <div className="controls-wrapper">
      <label> {t("Ставка")}: </label>
      <div className="controls-input-wrapper">
        <button disabled onClick={decreaseInvestment}>-</button>
        <input
          type="number"
          placeholder="0 USDT"
          value={parseInt(investment).toString()}
          onChange={(e) => handleInputChange(e)}
          min={0}
          step="any"
        />
        <button disabled onClick={increaseInvestment}>+</button>
      </div>
    </div>
  );
};
