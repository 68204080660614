// use this function to pick appropriate message to show in popup description when bot
// has been launched

function pickSuccessMsg(tariff, userData) {
  let successMsg;
  if (tariff === "base") {
    if (userData?.is_base_user) {
      successMsg = "Алгоритм успешно пополнен";
    } else {
      successMsg = "AI начнет работу в ближайшее время";
    }
  } else if (tariff === "pro") {
    if (userData?.is_pro_user) {
      successMsg = "Алгоритм успешно пополнен";
    } else {
      successMsg = "AI начнет работу в ближайшее время";
    }
  } 
  else if (tariff === "combo") {
    if (userData?.is_combo_user) {
      successMsg = "Алгоритм успешно пополнен";
    } else {
      successMsg = "AI начнет работу в ближайшее время";
    }
  } 
  else {
    console.log("Invalid tariff type");
  }

  return successMsg;
}

export { pickSuccessMsg };
