import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Timer } from "../../assets/icons/binary-option/timer.svg";

export const CountdownTimer = ({ expiration_time, id, handleBetCompleted }) => {
  const [targetTime] = useState(new Date(expiration_time));
  const [timeRemaining, setTimeRemaining] = useState(() => {
    const now = new Date();
    const difference = targetTime - now; // Add 5 seconds (5 * 1000 milliseconds)
    return Math.floor(difference / 1000);
  });
  const { t } = useTranslation();

  useEffect(() => {
    const timer = setInterval(() => {
      const now = new Date();
      const difference = targetTime - now;
      const remaining = Math.floor(difference / 1000);
      setTimeRemaining(remaining);

      if (remaining <= 0) {
        clearInterval(timer);
        handleBetCompleted(id);
      }
    }, 1000);

    // Clear the interval when the component is unmounted
    return () => clearInterval(timer);
  }, [targetTime, id, handleBetCompleted]);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <>
    <Timer />
      {timeRemaining > 0 ? formatTime(timeRemaining) : t("Завершено")}
    </>
  );
};
