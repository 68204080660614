import { useState, Fragment } from "react";
import { useTranslation } from "react-i18next";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
import { RefferalsSubtree } from "./RefferalsSubtree";
import { ReactComponent as ArrowExpandTree } from "../../assets/icons/refferals-tree-table/arrow-expand-tree.svg";
export const TableLarge = ({ refferals }) => {
  const { t } = useTranslation();
  const [expandedRows, setExpandedRows] = useState([]);

  // Toggle row expansion
  const toggleExpandRow = (idx) => {
    setExpandedRows(
      (prevExpandedRows) =>
        prevExpandedRows.includes(idx)
          ? prevExpandedRows.filter((rowIdx) => rowIdx !== idx) // Collapse row
          : [...prevExpandedRows, idx] // Expand row
    );
  };

  return (
    <table className="refferals-tree-table">
      <thead>
        <tr>
          {[
            "Линия",
            "Имя",
            "Дата Рег",
            "Кол-во реф",
            "Ранг",
            "Личный вклад",
            "Оборот команды",
          ].map((name, idx) => (
            <th key={`table-large-th-${idx}`}>{t(name)}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {!refferals?.length && (
          <div className="placeholder">
            <p>{t("Рефералы не найдены")}</p>
          </div>
        )}
        {refferals?.length > 0 &&
          refferals.map((ref, idx) => (
            <Fragment key={`parent-${idx}-table-row`}>
              <div className="user-network-filter-wrapper">
                <button>{t("Пользователь")}</button>
                {/* <button>{t("Сеть")}</button> */}
              </div>
              <tr>
                <td className="cell-with-opener">
                  {ref.referrals.length > 0 && (
                    <button onClick={() => toggleExpandRow(idx)}>
                      <ArrowExpandTree
                        className={
                          expandedRows.includes(idx)
                            ? "arrow-expand-tree expanded"
                            : "arrow-expand-tree"
                        }
                      />
                    </button>
                  )}
                  1
                </td>
                <td className="email-cell">{ref.email}</td>
                <td>
                  {transformDateTimeForSessionsTable(ref.registration_date)}
                </td>
                <td>{ref.referrals_count}</td>
                <td>{ref.current_referral_level}</td>
                <td>{ref.deposit_sum.toFixed(2)} USDT</td>
                <td>{ref.total_referrals_deposit_sum.toFixed(2)} USDT</td>
              </tr>

              {expandedRows.includes(idx) && (
                <RefferalsSubtree
                  subRefferalsArray={ref.referrals}
                  parentIdx={`parent-${idx}`}
                  level={2}
                />
              )}
            </Fragment>
          ))}
      </tbody>
    </table>
  );
};
