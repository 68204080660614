import { Triangle } from "react-loader-spinner";
import { useTranslation } from "react-i18next";
export const BinaryChartLoader = ({extended}) => {
  const {t} = useTranslation()
  return (
    <div className={`binary-chart-loader-wrapper ${extended ? 'extended': ''}`}>
      <Triangle
        visible={true}
        height="80"
        width="80"
        color="rgba(252,70,107,1)"
        ariaLabel="triangle-loading"
        wrapperStyle={{}}
        wrapperClass=""
      />
      <p>{t("Загрузка")+"..."}</p>
    </div>
  );
};
