import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectTheme } from "../toolkitReducers/selectors";

import { lngs } from "../helpers/lists";
const LanguageSelect = () => {
  const theme = useSelector(selectTheme);
  const [isOpen, setIsOpen] = useState(false);


  const { i18n } = useTranslation();
  const dropdownWindowRef = useRef(null);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleClickOutside = (event) => {
    if (
      dropdownWindowRef.current &&
      !dropdownWindowRef.current.contains(event.target)
    ) {
      setIsOpen(false);
    }
  };

  const handleLanguageChange = (lang) => {
    // setCurrentLanguage(language);
    i18n.changeLanguage(lang);
    setIsOpen(false);
  };

  const handleLanguageClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="custom-select-wrapper">
      <div className="custom-select-pages">
        <button
          tabIndex="0"
          aria-expanded="false"
          aria-controls="dropdown-1"
          onClick={handleLanguageClick}
        >
          <span
            data-button-label
            style={{ color: theme === "light" ? "black" : "white" }}
          >
            {i18n.resolvedLanguage.toUpperCase()}
          </span>

          <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 6L11.1962 0.75H0.803848L6 6Z" fill="#4D4D4D" />
          </svg>
        </button>

        {isOpen && (
          <ul data-dropdown id="dropdown-2" ref={dropdownWindowRef}>
            {Object.keys(lngs).map((lang) => (
              <li
                key={lang}
                className="btn--reset link custom-select__dropdown-button"
              >
                <button
                  className="btn--reset link custom-select__dropdown-button"
                  onClick={() => handleLanguageChange(lang)}
                  disabled={i18n.resolvedLanguage === lang}
                >
                  {lngs[lang].shortName}
                </button>
              </li>
            ))}
          </ul>
        )}

        <input
          type="hidden"
          className="custom-select__input-hidden"
          value="10"
        />
      </div>
    </div>
  );
};
export { LanguageSelect };
