import { useTranslation } from "react-i18next";

export const DevelopmentSublist = ({ items }) => {
  const { t } = useTranslation();
  return (
    <ul className="goals-sublist">
      {items.map((item) => (
        <li key={item}>{t(item)}</li>
      ))}
    </ul>
  );
};
