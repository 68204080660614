import { useTranslation } from "react-i18next";
export const BalanceBlock = ({ balanceAmount, coinName, balanceName }) => {
  const { t } = useTranslation();
  const trunc = (number) => {
    let precision = 5;
    let truncatedNumber =
      Math.trunc(number * Math.pow(10, precision)) / Math.pow(10, precision);

    return truncatedNumber;
  };
  return (
    <div className="profile-section__client-btc-info">
      <p className="balance-section_info_name">{t(balanceName)}:</p>
      <p className="balance-section_info_value">
        {coinName === "usdt"
          ? trunc(balanceAmount)
          : parseFloat(balanceAmount).toFixed(2)}{" "}
        {coinName}
      </p>
    </div>
  );
};
