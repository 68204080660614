import { useTranslation } from "react-i18next";
import { NextButton } from "./NextButton";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
export const TradingOffer = ({
  launchBot,
  offerName,
  sum,
  profit,
  customizable,
  blured = false,
}) => {
  const { t } = useTranslation();
  const { data: userData } = useGetUserDataQuery();
  return (
    <div
      className={`${
        blured ? "trading-offer-wrapper blured" : "trading-offer-wrapper"
      }`}
    >
      <h3>{t(offerName)}</h3>
      <div className="details-row">
        <p>{t("Cумма")}:</p>
        <p>{t("От") + " " + sum} USDT</p>
      </div>
      <div className="details-row">
        <p>{t("Доходность")}:</p>
        <p>
          {profit}+%/{t("мес.")}
        </p>
      </div>
      <div className="details-row">
        <p>{t("Возможность тонкой настройки")}:</p>
        <p>{t(customizable ? "Доступно" : "Отсутствует")}</p>
      </div>
      {sum < 50 && (
        <div className="buttons-wrapper">
          <NextButton
            onClick={launchBot}
            text="Запустить AI"
            disabled={userData?.is_base_user || blured}
          />
          <NextButton
            onClick={launchBot}
            text="Пополнить"
            disabled={!userData?.is_base_user || blured}
          />
        </div>
      )}
      {/* change condition to render markup above according to new algorithm launch rules */}
      {sum >= 50 && offerName === "Arbitrage AI" && (
        <div className="buttons-wrapper">
          <NextButton
            onClick={launchBot}
            text="Запустить AI"
            disabled={userData?.is_pro_user || blured}
          />
          <NextButton
            onClick={launchBot}
            text="Пополнить"
            disabled={!userData?.is_pro_user || blured}
          />
        </div>
      )}
      {sum >= 50 && offerName === "Combo AI" && (
        <div className="buttons-wrapper">
          <NextButton
            onClick={launchBot}
            text="Запустить AI"
            disabled={userData?.is_combo_user || blured}
          />
          <NextButton
            onClick={launchBot}
            text="Пополнить"
            disabled={!userData?.is_combo_user || blured}
          />
        </div>
      )}
    </div>
  );
};
