import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  setToken,
  privateFetch,
  // getToken,
  clearToken,
  setStorage,
} from "../helpers";
import BrowserDetector from "browser-dtector";

// const userLanguage = navigator.language || navigator.userLanguage;

let lang = localStorage.getItem("lang");

if (!lang) localStorage.setItem("lang", "en");

const browser = new BrowserDetector(window.navigator.userAgent);
let showUserAgent = browser.parseUserAgent();

const handleRejected = (state, action) => {
  state.isLoading = false;
  state.error = action.error.message || action.error.stack;
};
// We need to change the setup of /get_user_data enpoint on backend and replace POST request with GET
// to provide a possibility of replacing asyncThunks with 
// RTK Query with all its data caching benefits
export const getUserData = createAsyncThunk(
  "async/getUserData",
  async function (param, options) {
    const response = await privateFetch("get_user_data/", {
      method: "POST",
      body: JSON.stringify({
        browser: showUserAgent.name,
        os: showUserAgent.platform,
        version: showUserAgent.version,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const registerNewUser = createAsyncThunk(
  "async/registerNewUser",
  async function (param, options) {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "register_user/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          username: param.username,
          last_name: param.last_name,
          email: param.email,
          password: param.password,
          ref_id: param.refID,
          phone: param.phone
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);

export const loginUser = createAsyncThunk(
  "async/loginUser",
  async function (param, options) {
    const response = await fetch(
      process.env.REACT_APP_API_URL + "login_user/",
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: param.useLogin,
          password: param.usePassword,
          browser: showUserAgent.name,
          os: showUserAgent.platform,
          version: showUserAgent.version,
        }),
      }
    );

    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
// user_password_reset    (route)
// new_password
// repeat_new_password
export const resetPassword = createAsyncThunk(
  "async/resetPassword",
  async function (param, options) {
    const response = await privateFetch("user_password_reset/", {
      method: "POST",
      body: JSON.stringify({
        new_password: param.password,
        repeat_new_password: param.confirmPassword,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      // throw new Error('Oops! Something went wrong')
      return options.rejectWithValue(data);
    }

    return data;
  }
);
export const setUserData = createAsyncThunk(
  "async/setUserData",
  async function (param, options) {
    const response = await privateFetch("get_user_data/", {
      method: "POST",
      body: JSON.stringify({
        browser: showUserAgent.name,
        os: showUserAgent.platform,
        version: showUserAgent.version,
      }),
    });
    const data = await response.json();

    if (!response.ok) {
      return options.rejectWithValue(data);
    }

    return data;
  }
);
const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    isAuth: false,
    fething: false,
    registered: false,
    error: "",
    registerErrors: "",
    liq: false,
    // liq: true
  },
  reducers: {
    userLogout: (state, action) => {
      // state={...state}
      state.user = null;
      state.isAuth = false;
      state.fething = false;
      state.error = "";
      state.registerErrors = "";

      clearToken();
    },
    resetRegister: (state) => {
      state.registered = false;
    },
    clearUserData: (state, action) => {
      state.user = null;
      state.isAuth = false;
      state.fething = false;
      state.error = "";
      state.registerErrors = "";
    },
    openLiq: (state, action) => {
      state.liq = true;
    },
    closeLiq: (state) => {
      state.liq = false;
    },
  },
    extraReducers: (builder) => {
      builder.addCase(resetPassword.pending, (state, action) => {
        state.fething = "loading";
      });
              builder.addCase(resetPassword.rejected, (state, action) => {
      state.fething = "rejected";
      state.error = action.error.message || action.error.stack;
              });
           builder.addCase(resetPassword.fulfilled, (state, action) => {
             state.fething = "fulfilled";
             state.registered = true;
             state.registerErrors = "";
           });
    builder.addCase(getUserData.pending, (state, action) => {
      state.fething = "loading";
    });
    builder.addCase(getUserData.fulfilled, (state, action) => {
      state.fething = "fullfilled";

      action.payload && setStorage(action.payload?.user);
      // /dashboard/get_crypto_prices/
      state.isAuth = true;
      if (action.payload) {
        state.user = action.payload;

        if (action.payload.user.liquidated === true) {
          state.liq = true;
        }
      }
      state.error = "";
      state.registerErrors = "";
    });
    builder.addCase(getUserData.rejected, (state, action) => {
      state.fething = "rejected";
      state.error = action.error.message || action.error.stack;
    });

    builder.addCase(loginUser.pending, (state, action) => {
      state.fething = "loading";
    });
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.fething = "fulfilled";

      if (action.payload.token) {
        setToken(action.payload);

        state.isAuth = true;
        state.user = action.payload;
        state.error = "";
        state.registerErrors = "";
      }
    });
    builder.addCase(loginUser.rejected, (state, action) => {
      state.fething = "rejected";
      state.error = action.error.message || action.error.stack;
    });
    builder.addCase(registerNewUser.pending, (state, action) => {
      state.fething = "loading";
      state.registerErrors = "";
    });
    builder.addCase(registerNewUser.fulfilled, (state, action) => {
      state.fething = "fulfilled";
      state.registered = true;
      state.registerErrors = "";
    });
    builder.addCase(registerNewUser.rejected, (state, action) => {
      state.fething = "rejected";

      state.registerErrors = action.payload;
    });
    builder.addCase(setUserData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(setUserData.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isLoggedIn = true;
      if (action.payload) {
        state.user = action.payload;

        if (action.payload.user.liquidated === true) {
          state.liq = true;
        }
      }
      state.error = null;
      state.registerErrors = null;
    });
    builder.addCase(setUserData.rejected, handleRejected);
  },
});

export const { userLogout, clearUserData, resetRegister, openLiq, closeLiq } =
  authSlice.actions;

export default authSlice.reducer;
