// symbols object is used to pass as props to PairSwitcher and Victory Crypto chart


const symbols = {
    "BTC/USDT": "BTCUSDT",
    "ETH/USDT": "ETHUSDT",
    "BNB/USDT": "BNBUSDT",
  };

  export {
    symbols
  }