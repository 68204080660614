import { useSelector } from "react-redux";
import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { selectCurrencies } from "../../toolkitReducers/selectors";
import { ProgressBarBlock } from "./ProgressBarBlock";
import { useGetUserDataQuery } from "../../toolkitReducers/cryptouch";
import { NextButton } from "./NextButton";
import { TransferCoinSelectionBlock } from "./TransferCoinSelectionBlock";
import { WarningTransferDescription } from "./WarningTransferDescription";
import { checkAmountToLaunchBot } from "../../helpers/checkUserFunds";
export const ReplenishBotBalance = ({
  handleReplenishBotBalance,
  promotion,
}) => {
  const currencies = useSelector(selectCurrencies);
  const [selectedCoin, setSelectedCoin] = useState(currencies[0]);
  const [percentage, setPercentage] = useState(0);
  const [amount, setAmount] = useState("0");
  const [sliderFillPercentage, setSliderFillPercentage] = useState(0);
  const { data, isFetching } = useGetUserDataQuery();
  const transferInputRef = useRef(null);
  const { t } = useTranslation();
  const [localError, setLocalError] = useState("");
  useEffect(() => {
    let getAmount = `trading_crypto_balance_${selectedCoin.value}`;
    const depositAmount = data && data[getAmount];
    transferInputRef.current.max = data?.[getAmount];
    setAmount(((depositAmount * parseFloat(percentage)) / 100).toString());
  }, [selectedCoin.value, percentage, data]);
  const handleCoinChange = (index) => {
    setSelectedCoin(currencies[index]);
  };

  const handlePercentageChange = ({ target }) => {
    setPercentage(target.value);
    setSliderFillPercentage(
      ((target.value - target.min) / (target.max - target.min)) * 100
    );
  };
  const showCurrentCoin = () => {
    let getAmount = `trading_crypto_balance_${selectedCoin.value}`;
    return data[getAmount] + " " + selectedCoin.value.toUpperCase();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let balance = data[`trading_crypto_balance_${selectedCoin.value}`];
    if (Number(amount) <= 0) {
      setLocalError("Summ should be more that 0");
      return;
    }
    if (Number(amount) > Number(balance)) {
      setLocalError("Summ should be less than ballance");
      return;
    }
    if (!data?.is_base_user) {
      const sufficient = checkAmountToLaunchBot(
        amount,
        promotion,
        selectedCoin?.value
      );
      if (!sufficient) {
        setLocalError(`Минимальная сумма для запуска алгоритма 10 USDT`);
        return;
      }
    }
    if (!data?.is_pro_user) {
      const sufficient = checkAmountToLaunchBot(
        amount,
        promotion,
        selectedCoin?.value
      );
      if (!sufficient) {
        setLocalError(`Минимальная сумма для запуска алгоритма 100 USDT`);
        return;
      }
    }
    if (!data?.is_combo_user) {
      const sufficient = checkAmountToLaunchBot(
        amount,
        promotion,
        selectedCoin?.value
      );
      if (!sufficient) {
        setLocalError(`Минимальная сумма для запуска алгоритма 100 USDT`);
        return;
      }
    }
    const { value: coin } = selectedCoin;
    handleReplenishBotBalance(promotion, amount, coin);
  };
  const changeAmountSumm = (e) => {
      setAmount(e.target.value);

  };
  return (
    <div className="transfer-wrapper">
      <h3>{t("Пополнить баланс алгоритма")}</h3>
      <div className="balance-block">
        <p className="balance-amount">{!isFetching && showCurrentCoin()}</p>
      </div>
      <ProgressBarBlock
        {...{
          percentage,
          handlePercentageChange,
          sliderFillPercentage,
          amount,
          selectedCoin,
          isFetching,
          showCurrentCoin,
        }}
      />
      <TransferCoinSelectionBlock
        {...{
          handleCoinChange,
          amount,
          changeAmountSumm,
        }}
        ref={transferInputRef}
      />
      <NextButton onClick={handleSubmit} text="Пополнить" />
      <WarningTransferDescription {...{ localError }} />
    </div>
  );
};
