import { CountdownTimer } from "../../Pages/BinaryOption/CountdownTimer";
import moment from "moment";
import { useSelector, useDispatch } from "react-redux";
import { selectBetHistory } from "../../toolkitReducers/selectors";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { setBetHistory } from "../../toolkitReducers/actions.slice";

import { betTypeToInitialLang, betStatusToInitialLang } from "../../helpers/transformStringsToInitialLang";
export const PendingBetsTable = () => {
  const { t } = useTranslation();
  const pendingBets = useSelector(selectBetHistory);
  const [completedBets, setCompletedBets] = useState([]);


  const handleBetCompleted = (id) => {
    dispatch(setBetHistory());
    if (!completedBets.includes(id)) {
      setCompletedBets((prevBets) => [...prevBets, id]);
    }
  };

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setBetHistory());
  }, [dispatch]);

  const allowRender = pendingBets && pendingBets.length && pendingBets;

  return allowRender ? (
    <div className="transaction-history-table-wrapper">
      <div className="table-inner-wrapper">
        <table>
          <thead>
            <tr className="pending-bets-table">
              <th>{t("ID ставки")}</th>
              <th>{t("Тип ставки")}</th>
              <th>{t("Сумма")}</th>
              <th>{t("Валюта")}</th>
              <th>{t("Время истечения")}</th>
              <th>{t("Статус")}</th>
              <th>{t("Таймер")}</th>
            </tr>
          </thead>
          <tbody>
            {pendingBets
              .map((p) => (
                <tr key={p.id} className="pending-bets-table">
                  <td>{p.id}</td>
                  <td>{t(betTypeToInitialLang(p.bet_type))}</td>
                  <td>{p.amount}</td>
                  <td>{p.currency.toUpperCase()}</td>
                  <td>{moment(p.expiration_time).format("h:mm")}</td>
                  <td>{t(betStatusToInitialLang(p.status))}</td>

                  <td className="cell-with-timer">
                    {!p.resolved && !completedBets.includes(p.id) ? (
                      <CountdownTimer
                        handleBetCompleted={handleBetCompleted}
                        expiration_time={p.expiration_time}
                        id={p.id}
                      />
                    ) : (
                      <>
                        {t("Завершено")}
                      </>
                    )}
                  </td>
                </tr>
              ))
              .reverse()}
          </tbody>
        </table>
      </div>
    </div>
  ) : (
    <></>
  );
};
