import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Arrow } from "../../assets/icons/modals/arrow-expand.svg";
import { symbols } from "../../helpers/symbols";
export const PairSwitcher = (props) => {
  const [expanded, setExpanded] = useState(false);
  const { selectedPair, setSelectedPair } = props;
  const { t } = useTranslation();

  const toggleDropdown = () => setExpanded(!expanded);

  const handlePairChange = (pair) => {
    setSelectedPair(pair);
    setExpanded(false);
  };
  return (
    <div className="pair-switcher-wrapper">
      <p className="heading">{t("Выбор пары")}</p>
      <div className="pair-selection-dropdown-wrapper">
        <span>
          {symbols[selectedPair].slice(0, 3) +
            "/" +
            symbols[selectedPair].slice(3, symbols[selectedPair].length)}
        </span>
        <button
          onClick={toggleDropdown}
          className={expanded ? "toggle-dropdown expanded" : "toggle-dropdown"}
        >
          <Arrow />
        </button>
        {expanded && (
          <ul className="pair-selection-dropdown">
            {Object.keys(symbols).map((pair) => (
              <li key={pair} onClick={() => handlePairChange(pair)}>
                <button
                  className={`pair ${selectedPair === pair &&"selected"}`}
                >
                  {pair}
                </button>
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};
