
import { useTranslation } from "react-i18next";
import { CustomSelect } from "../Binary/CustomSelect";
import { useState } from "react";
import { transformDateTimeForSessionsTable } from "../../helpers/createDateString";
import { useGetUserSessionsQuery } from "../../toolkitReducers/cryptouch";
import { selectTheme } from "../../toolkitReducers/selectors";
import { useSelector } from "react-redux";
export const SessionsTable = () => {
  const { t } = useTranslation();
  const [perPage, setPerPage] = useState(5);
  const { data } = useGetUserSessionsQuery();
  const theme = useSelector(selectTheme)
  const handleDisplayPerPage = (count = null) => {
    setPerPage(count);
  };

  return (
    <section
      className={
        theme === "light"
          ? "sessions-section medium-wrapper"
          : "sessions-section medium-wrapper dark-profile"
      }
    >
      <div className="sessions-section__header">
        <h2 className="sessions-section__header-heading">
          {t("История сеансов")}
        </h2>
        <CustomSelect
          handleDisplayPerPage={handleDisplayPerPage}
          perPage={perPage}
        />
      </div>

      <div className="sessions-section__table-wrapper">
        <table className="sessions-section__table-body">
          <thead>
            <tr className="sessions-section__table-heading-row rel">
              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>{t("Дата")}</span>
                  <span>
                    <svg
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9 5.5L5 1.5L1 5.5"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>{t("IP ADDRESS")}</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                    >
                      <path
                        d="M9 6L5 2L1 6"
                        stroke="#FFF831"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </span>
                </div>
              </th>
              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>OS</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                    >
                      <path
                        d="M9 6L5 2L1 6"
                        stroke="#FFF831"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </span>
                </div>
              </th>

              <th>
                <div className="sessions-section__table-td-wrapper">
                  <span>{t("Браузер")}</span>
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                    >
                      <path
                        d="M9 6L5 2L1 6"
                        stroke="#FFF831"
                        strokeWidth="1.5"
                      />
                    </svg>
                  </span>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {data &&
              data.length > 0 &&
              [...data]
                .reverse()
                .splice(0, perPage || data.length)
                .map((session, index) => (
                  <tr key={index} className="sessions-section__table-body-row">
                    <td>
                      {transformDateTimeForSessionsTable(
                        session.last_login_date
                      )}
                    </td>
                    <td>{session.last_ip_address}</td>
                    <td>{session.last_os}</td>
                    <td>{session.last_browser}</td>
                  </tr>
                ))}
          </tbody>
        </table>
      </div>

      <div className="sessions-section__controls">
        <div className="sessions-section__controls-counter">
          <span className="sessions-section__controls-counter-current-page">
            0
          </span>
          /
          <span className="sessions-section__controls-counter-all-pages">
            0
          </span>
        </div>
        <div className="sessions-section__controls-buttons">
          <button
            type="button"
            className="sessions-section__controls-first-page btn--reset link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M1 0V14M13 1L7 7L13 13"
                stroke="currentColor"
                strokeWidth="2"
              />
            </svg>
          </button>
          <button
            type="button"
            className="sessions-section__controls-previous-page btn--reset link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
            >
              <path d="M8 1L2 7L8 13" stroke="currentColor" strokeWidth="2" />
            </svg>
          </button>

          <button
            type="button"
            className="sessions-section__controls-next-page btn--reset link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="9"
              height="14"
              viewBox="0 0 9 14"
              fill="none"
            >
              <path d="M1 1L7 7L1 13" stroke="currentColor" strokeWidth="2" />
            </svg>
          </button>

          <button
            type="button"
            className="sessions-section__controls-last-page btn--reset link"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                d="M13 0V14M1 1L7 7L1 13"
                stroke="currentColor"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </section>
  );
};
