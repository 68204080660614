import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useSelector } from "react-redux";
import { selectTheme } from "../../toolkitReducers/selectors";
const CustomSelect = ({
  text = "Отобразить",
  handleDisplayPerPage,
  perPage,
}) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const theme = useSelector(selectTheme);
  const handleSelect = (value) => {
    setIsOpen(false);
    handleDisplayPerPage(value);
  };


  return (
    <div
      className={
        theme === "light" ? "custom-select-wrapper" : "custom-select-wrapper dark-profile"
      }
    >
      <span className="custom-select-label">{t(text)}</span>
      <div className="custom-select-pages">
        <button
          onClick={() => toggle()}
        >
          <span>{perPage || t("Все")}</span>

          <svg
            width="12"
            height="6"
            viewBox="0 0 12 6"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M6 6L11.1962 0.75H0.803848L6 6Z" fill="#4D4D4D" />
          </svg>
        </button>

        {isOpen && (
          <ul data-dropdown id="dropdown-2">
            {
              [1,2,3,5,10,30,null].map((value, idx) => (
                <li  key={value? value+idx : 'lastValue'}>
                <button
                  className="btn--reset link custom-select__dropdown-button"
                  onClick={() => handleSelect(value)}
                >
                  {value ? value : t("Все")}
                </button>
              </li>
              ))
            }

          </ul>
        )}


      </div>
    </div>
  );
};
export { CustomSelect };
