import Slider from "react-slick";
import { useTranslation } from "react-i18next";
import { useGetNewsQuery } from "../../toolkitReducers/cryptouch";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
export const News = () => {
  const { t } = useTranslation();
  const { data: newsList, isFetching } = useGetNewsQuery();

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    initialSlide: 0,
    slidesToShow: 4, // Show 4 items by default
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2, // Show 2 items below 991px
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1, // Show 1 item below 768px
          slidesToScroll: 1,
          swipeToSlide: true,
        },
      },
    ],
  };

  return (
    <section className="main-page-section news-section">
      <div className="section-inner-container">
        <h3>{t("О нас говорят")}</h3>
        <div className="slider-container">
          {!isFetching && (
            <Slider {...settings}>
              {newsList?.news.map(({ title, description, image }, idx) => (
                <div key={title + idx}>
                  <div className="thumb">
                    <img src={image} alt={title} />
                  </div>
                  <h4>{title}</h4>
                  <p>{description}</p>
                </div>
              ))}
            </Slider>
          )}
        </div>
      </div>
    </section>
  );
};
